import * as Yup from 'yup'

const schema = Yup.object({
  preferredTransitModes: Yup.array().of(Yup.string()).min(1),
  firstMileModes: Yup.array().of(Yup.number()).min(1),
  firstMileBikeTypes: Yup.number().required(),
  lastMileModes: Yup.array().of(Yup.number()).min(1),
  lastMileBikeTypes: Yup.number().required(),
  timeCostOfNormalTravel: Yup.number().required(),
  timeCostOfBusinessTravel: Yup.number().required(),
  timeCostOfLastMile: Yup.number().required(),
  transitOfTimeCost: Yup.number().required(),
  transitOfFare: Yup.number().required(),
  speedOfWalking: Yup.number().required(),
  speedOfCycling: Yup.number().required(),
  speedOfDriving: Yup.number().required(),
  labeling: Yup.number().required(),
  unitFare: Yup.object({
    thsr: Yup.number().required(),
    tra: Yup.number().required(),
    bus: Yup.number().required(),
    mrt: Yup.number().required(),
    lrt: Yup.number().required(),
    ferry: Yup.number().required(),
    gondola: Yup.number().required(),
  }).required(),
})

export const VALIDATION_SCHEMA = {
  [window.LOCATION.TAIWAN]: schema,
  [window.LOCATION.HOUSTON]: schema.shape({
    firstMileBikeTypes: Yup.array().of(Yup.number()).min(1),
    lastMileBikeTypes: Yup.array().of(Yup.number()).min(1),
  }),
}
